<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="90px"
      class="add-edit-report"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item label="日期" prop="reportDate">
        <el-date-picker
          v-model="dataForm.reportDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="dataForm.type" @change="dataFormTypeChange()">
          <el-radio :label="0">阶段复习</el-radio>
          <el-radio :label="1">单次考试</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="年级" prop="gradeId">
        <el-select
          v-model="dataForm.gradeId"
          placeholder="请选择"
          @change="yearChange()"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入学年份" prop="year">
        <el-radio-group v-model="dataForm.year" @change="examOptionsChange()">
          <el-radio v-for="(v, i) in yearList" :key="i" :label="v.label">{{
            v.value
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="examOptionVisible && dataForm.type == '1'"
        label="考试"
        prop="examId"
      >
        <el-select v-model="dataForm.examId" popper-class="select-items">
          <el-option
            v-for="item in examOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id + ''"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; font-size: 12px; margin-left: 30px">{{
              item.examDate
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="examOptionVisible && dataForm.type == '0'"
        label="考试"
        prop="examIdList"
      >
        <el-select
          v-model="dataForm.examIdList"
          popper-class="select-items"
          multiple
        >
          <el-option
            v-for="item in examOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id + ''"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; font-size: 12px; margin-left: 30px">{{
              item.examDate
            }}</span>
          </el-option>
        </el-select> </el-form-item
      ><el-form-item label="学科" prop="subjectIdList">
        <el-select v-model="dataForm.subjectIdList" multiple>
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getObj, addObj, putObj } from "@/core/api/analy/analyreport";
import { fetchListByGradeAndYearForAnalyReport } from "@/core/api/exam/exam";
import {
  gradeOptions,
  primaryOptions,
  subjectOptions,
} from "@/core/util/constdata";
import { filterSubject, getYear } from "@/core/util/util";
import { getGradeList } from "@/core/api/school/schoolgradeclass";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        name: "",
        reportDate: "",
        type: "",
        gradeId: "",
        year: "",
        examId: "",
        examIdList: [],
        subjectIds: "",
        subjectIdList: [],
      },
      gradeOptions: gradeOptions,
      primaryOptions: primaryOptions,
      subjectOptions: subjectOptions,
      dataRule: {
        name: [{ required: true, message: "名称不能为空", trigger: "change" }],
        reportDate: [
          { required: true, message: "日期不能为空", trigger: "change" },
        ],
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "change",
          },
        ],
        gradeId: [
          { required: true, message: "年级不能为空", trigger: "change" },
        ],
        year: [
          { required: true, message: "入学年份不能为空", trigger: "change" },
        ],
        examId: [
          { required: true, message: "考试不能为空", trigger: "change" },
        ],
        examIdList: [
          { required: true, message: "考试不能为空", trigger: "change" },
        ],
        subjectIdList: [
          { required: true, message: "学科列表不能为空", trigger: "change" },
        ],
      },
      // 考试列表展示和数据
      examOptionVisible: false,
      examOptions: [],

      // 班级列表
      gradeListData: [],
      yearList: getYear(),
    };
  },
  created() {
    this.gradeOptions = [...this.gradeOptions].concat(this.primaryOptions);
  },
  methods: {
    getGradeList() {
      getGradeList().then((res) => {
        // console.log(res);
        this.gradeListData = res.data.data;
        // this.yearList = [];
        // this.gradeListData.forEach((index) => {
        //   this.yearList.push(index.year);
        // });
        // this.yearList = [...new Set(this.yearList)];
        // year
      });
    },
    init(id) {
      this.getGradeList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm.subjectIds = "";
        this.dataForm.subjectIdList = [];
        if (this.dataForm.id) {
          getObj(this.dataForm.id).then((response) => {
            this.dataForm = Object.assign(this.dataForm, response.data.data);
            if (this.dataForm.type == 1) {
              this.dataForm.examId = this.dataForm.examIds;
            } else {
              this.dataForm.examIdList = this.dataForm.examIds.split(",");
            }
            if (this.dataForm.gradeId > 400) {
              this.subjectOptions = filterSubject(3);
            } else {
              this.subjectOptions = filterSubject(2);
            }
            this.dataForm.gradeId = parseInt(this.dataForm.gradeId);
            this.dataForm.subjectIdList = this.dataForm.subjectIds.split(",");
            this.dataForm.subjectIdList.map((i, a) => {
              this.dataForm.subjectIdList[a] = Number(i);
            });
            this.examOptionVisible =
              this.dataForm.gradeId && this.dataForm.year;
            this.getRemoteExamOptions();
          });
        } else {
          this.getRemoteExamOptions();
        }
      });
    },
    dataFormTypeChange() {
      this.dataForm.examId = "";
      this.dataForm.examIdList = [];
    },
    yearChange() {
      // this.yearList = [];
      // if (this.dataForm.gradeId > 400) {
      //   // this.subjectOptions = filterSubject(3);
      //   this.gradeListData
      //     .filter((item) => item.level == 3)
      //     .forEach((index) => {
      //       this.yearList.push(index.year);
      //     });
      // } else {
      //   this.gradeListData
      //     .filter((item) => item.level == 2)
      //     .forEach((index) => {
      //       this.yearList.push(index.year);
      //     });
      //   // this.subjectOptions = filterSubject(2);
      // }
      this.dataForm.year = "";
      this.dataForm.examId = "";
      this.dataForm.examIdList = [];
      this.dataForm.subjectIdList = [];
      this.getRemoteExamOptions();
    },
    examOptionsChange() {
      if (this.dataForm.gradeId > 400) {
        this.subjectOptions = filterSubject(3);
      } else {
        this.subjectOptions = filterSubject(2);
      }
      this.dataForm.subjectIdList = "";

      this.dataForm.examId = "";
      this.dataForm.examIdList = [];
      this.dataForm.subjectIdList = [];
      this.getRemoteExamOptions();
    },
    getRemoteExamOptions() {
      this.examOptionVisible = !!(this.dataForm.gradeId && this.dataForm.year);
      if (this.examOptionVisible) {
        var requestObj = {
          gradeId: this.dataForm.gradeId,
          year: this.dataForm.year,
          size: 1000,
        };
        fetchListByGradeAndYearForAnalyReport(requestObj).then((data) => {
          this.examOptions = data.data.data.records;
        });
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.dataForm.type == 1) {
            this.dataForm.examIds = this.dataForm.examId;
          } else {
            this.dataForm.examIds = this.dataForm.examIdList.join(",");
          }
          this.dataForm.subjectIds = this.dataForm.subjectIdList.join(",");
          if (this.dataForm.id) {
            putObj(this.dataForm).then((data) => {
              if (data && data.data && data.data.code === 0) {
                this.$message.success("修改成功");
                this.visible = false;
                this.$emit("refreshDataList");
              } else {
                var msg = "";
                if (data && data.data && data.data.msg) {
                  msg = data.data.msg;
                }
                if (!msg) {
                  msg = "修改失败";
                }
                this.$message.error(msg);
              }
            });
          } else {
            addObj(this.dataForm).then((data) => {
              if (data && data.data && data.data.code === 0) {
                this.$message.success("添加成功");
                this.visible = false;
                this.$emit("refreshDataList");
              } else {
                var msg = "";
                if (data && data.data && data.data.msg) {
                  msg = data.data.msg;
                }
                if (!msg) {
                  msg = "添加失败";
                }
                this.$message.error(msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// .add-edit-report {
.select-items {
  max-width: 500px;
  overflow-x: auto;
}
// }
</style>
