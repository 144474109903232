<template>
  <div class="report-page">
    <div class="report-page-header">
      <div class="page-header-label">精准提分</div>
      <div class="page-header-search">
        <div class="search-input" @click="showSearchPopup">
          <div class="prefix">
            <img src="@/assets/imgs/analy/icon-search.png" alt="" />
            <!-- <span>搜索项目</span> -->
          </div>
          <el-input
            v-model="searchItems.inputSearch"
            class="main-search-input"
            placeholder="搜索项目"
          >
          </el-input>
          <div class="suffix"><i class="el-icon-caret-bottom"></i></div>
        </div>
        <div v-if="showSearchItems" class="search-items">
          <div class="search-item-input">
            <el-input
              v-model="searchItems.inputSearch"
              placeholder="搜索项目"
              @keyup.enter.native="examSearch"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <i class="el-icon-caret-top" @click="showSearchPopup"></i>
          </div>
          <div class="select-item-select">
            <el-select
              v-model="searchItems.examGrade"
              clearable
              multiple
              collapse-tags
              placeholder="全部学段"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchItems.examYear"
              clearable
              multiple
              placeholder="全部年级"
            >
              <el-option
                v-for="item in examYearOptions"
                :key="item.value"
                :label="item.label + ' 级'"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchItems.examType"
              clearable
              multiple
              placeholder="全部类型"
            >
              <el-option
                v-for="item in reportTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchItems.examSubject"
              clearable
              multiple
              placeholder="全部学科"
            >
              <el-option
                v-for="item in examsubjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item-btn" @click="examSearch()">搜索</div>
        </div>
      </div>
      <div class="page-header-btn">
        <div class="new-items" @click="addOrUpdateHandle()">
          新建项目<i class="el-icon-plus el-icon--right"></i>
        </div>
        <div class="foldornot" @click="collapseTableItems">
          <div v-if="openAllItems" class="foldItems">
            <img src="@/assets/imgs/analy/icon-fold.png" alt="" />
            折叠所有条目
          </div>
          <div v-else class="openItems">
            <img src="@/assets/imgs/analy/icon-open.png" alt="" />
            展开所有条目
          </div>
        </div>
      </div>
    </div>
    <div class="report-page-content">
      <div v-for="(val, key) in dataList" :key="key" class="each-report">
        <div :class="openAllItems ? 'report-header' : 'report-header-only'">
          <div class="report-info">
            <div class="report-title">{{ val.name }}</div>
            <div class="report-level">
              <div class="report-gradeortype">
                {{
                  (val.grade.startsWith("高") ? "高" : "初") + val.year + "级"
                }}
              </div>
              <div class="report-gradeortype">
                {{ findLabelByValue(reportTypeOptions, val.type) }}
              </div>
              <div class="report-gradeortype">{{ val.reportDate }}</div>
            </div>
          </div>
          <div class="report-btns">
            <div class="btns-group">
              <div
                v-if="val.type == 1"
                class="btns"
                @click="examStudentHandle(val.id, val.name, val.examIds)"
              >
                考生管理
              </div>
              <template v-if="val.type == 1"> | </template>

              <div class="btns" @click="examTemplateHandle(val)">试题管理</div>
              |
              <div class="btns" @click="examNewResult(val)">成绩管理</div>
              <!-- |
            
              <div class="btns" @click="examResultHandle(val.id, val.name)">
                报告下载
              </div>
              |
              <div class="btns" @click="examPaperHandle(val.id)">打印管理</div>
              |
              <div class="btns" @click="examRankingHandle()">页码统计</div> -->
            </div>
            <div class="btns-group">
              <div class="btns" @click="addOrUpdateHandle(val.id)">修改</div>
              |
              <div class="btns" @click="deleteHandle(val.id)">删除项目</div>
            </div>
          </div>
        </div>
        <div class="report-content">
          <el-table
            v-if="val.showSubjectList"
            v-loading="dataListLoading"
            :data="val.subjectList"
            border
            style="width: 1220px; margin: 0px auto"
            :cell-class-name="tableCellClassName"
            class="report-table"
          >
            <el-table-column
              prop="subjectName"
              header-align="center"
              align="center"
              label="科目"
              min-width="8%"
            ></el-table-column>
            <el-table-column
              prop="anwserSheetId"
              header-align="center"
              align="center"
              label="原题"
              min-width="12.6%"
            >
              <template slot-scope="scope">
                <div class="display-or-not">
                  <img
                    class="show-icon"
                    src="@/assets/imgs/icon-remove.png"
                    alt=""
                  />
                  <div
                    class="show-btn"
                    @click="goToReportDetailPage(scope.row, 'original', val)"
                  >
                    <img src="@/assets/imgs/analy/icon-set.png" alt="" />
                    <span>进入管理</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="statusAnswer"
              header-align="center"
              align="center"
              label="变式题"
              min-width="12.6%"
            >
              <template slot-scope="scope">
                <div class="display-or-not">
                  <img
                    class="show-icon"
                    src="@/assets/imgs/icon-remove.png"
                    alt=""
                  />
                  <div
                    class="show-btn"
                    @click="goToReportDetailPage(scope.row, 'alternative', val)"
                  >
                    <img src="@/assets/imgs/analy/icon-set.png" alt="" />
                    <span>进入管理</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="statusScan"
              header-align="center"
              align="center"
              label="成绩表"
              min-width="12.6%"
            >
              <template slot-scope="scope">
                <div class="display-or-not">
                  <img
                    class="show-icon"
                    src="@/assets/imgs/icon-remove.png"
                    alt=""
                  />
                  <div
                    class="show-btn"
                    @click="goToReportDetailPage(scope.row, 'score', val)"
                  >
                    <img src="@/assets/imgs/analy/icon-set.png" alt="" />
                    <span>进入管理</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="statusReviewAssaign"
              header-align="center"
              align="center"
              label="报告参数"
              min-width="12.6%"
            >
              <template slot-scope="scope">
                <div class="display-or-not">
                  <img
                    class="show-icon"
                    src="@/assets/imgs/icon-remove.png"
                    alt=""
                  />
                  <div
                    class="show-btn"
                    @click="
                      goToReportDetailPage(scope.row, 'studentReport', val)
                    "
                  >
                    <img src="@/assets/imgs/analy/icon-set.png" alt="" />
                    <span>进入管理</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="statusReviewControl"
              header-align="center"
              align="center"
              label="报告生成"
              min-width="41.6%"
            >
              <template slot-scope="scope">
                <div class="display-or-not">
                  <el-progress
                    :percentage="calculateProgress(scope.row)"
                    :color="customColorMethod"
                    :stroke-width="12"
                    :show-text="false"
                    stroke-linecap="square"
                    class="show-progress"
                  ></el-progress>
                  <div
                    class="show-btn"
                    @click="goToReportDetailPage(scope.row, 'score', val)"
                  >
                    <img src="@/assets/imgs/analy/icon-set.png" alt="" />
                    <span>查看详情</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="dataList.length < 1" class="none-list">
        <img src="@/static/resources/none.svg" alt="" />
        <p>暂未找到数据</p>
      </div>
    </div>

    <!-- begin: pagination-->
    <div v-if="dataList.length > 0" class="pagination">
      <div class="page-totle">共{{ totalPage }}条</div>
      <span>第</span>
      <el-select
        v-model="pageIndex"
        placeholder="1"
        class="page-select"
        @change="pageSelect"
      >
        <el-option
          v-for="item in Math.ceil(totalPage / pageSize)"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <span style="margin-right: 64px"
        >页，共<span style="padding: 0 8px">{{
          Math.ceil(totalPage / pageSize)
        }}</span
        >页</span
      >
      <ul class="page-jump">
        <li>
          <a v-if="pageIndex > 1" @click="toFirstPage">
            <img src="@/assets/imgs/analy/icon-page-first.png" alt="第一页" />
          </a>
          <a v-else>
            <img
              src="@/assets/imgs/analy/icon-page-first-50.png"
              alt="第一页"
            />
          </a>
        </li>
        <li>
          <a v-if="pageIndex > 1" @click="pageIndex--, pageClick()">
            <img
              src="@/assets/imgs/analy/icon-page-previous.png"
              alt="上一页"
            />
          </a>
          <a v-if="pageIndex == 1">
            <img
              src="@/assets/imgs/analy/icon-page-previous-50.png"
              alt="上一页"
            />
          </a>
        </li>
        <li>
          <a
            v-if="pageIndex != Math.ceil(totalPage / pageSize)"
            @click="pageIndex++, pageClick()"
          >
            <img src="@/assets/imgs/analy/icon-page-next.png" alt="下一页" />
          </a>
          <a v-else>
            <img src="@/assets/imgs/analy/icon-page-next-50.png" alt="下一页" />
          </a>
        </li>
        <li>
          <a
            v-if="pageIndex != Math.ceil(totalPage / pageSize)"
            @click="toLastPage"
          >
            <img src="@/assets/imgs/analy/icon-page-last.png" alt="最后一页" />
          </a>
          <a v-else>
            <img
              src="@/assets/imgs/analy/icon-page-last-50.png"
              alt="最后一页"
            />
          </a>
        </li>
      </ul>
    </div>
    <!-- end: pagination-->

    <!-- 弹窗, 新增 / 修改 -->
    <TableForm
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    />

    <!-- 弹窗, 结束考试提示 -->
    <el-dialog title="提示" :visible.sync="endExamDialogVisible" width="30%">
      <span
        >确定要结束考试流程吗？
        （提示：结束考试流程后，可根据需要设定统计参数，生成统计分析结果）</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="endExamDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="endExamDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchList,
  delObj,
  queryFetchList,
} from "@/core/api/analy/analyreport";
import TableForm from "./analyreport-form";
import { getYear } from "@/core/util/util";
import { findByValue, setCookie } from "@/core/util/util";
import { mapGetters } from "vuex";
import {
  // examYearOptions,
  gradeOptions,
  reportTypeOptions,
  subjectOptions,
} from "@/core/util/constdata";

export default {
  name: "AnalyReport",
  components: {
    TableForm,
  },
  data() {
    return {
      percentage: 20,

      //搜索框下拉项
      showSearchItems: false,
      searchItems: {
        inputSearch: "",
        examGrade: [],
        examYear: [],
        examType: [],
        examSubject: [],
      },
      //展开/折叠
      openAllItems: true,

      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: true,
      examYearOptions: getYear(),
      gradeOptions: gradeOptions,
      reportTypeOptions: reportTypeOptions,
      examsubjectOptions: subjectOptions,
      examTypeValue: "0",
      examYearValue: "0",

      endExamDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  watch: {},
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getDataList();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},

  methods: {
    btnClick(num) {
      if (num != this.pageIndex) {
        this.pageIndex = num;
      }
    },
    pageClick() {
      // this.getDataList();
      this.getDataQueryList();
    },
    pageSelect() {
      this.btnClick(this.pageIndex);
      this.pageClick();
    },
    toFirstPage() {
      this.pageIndex = 1;
      this.pageSelect();
    },
    toLastPage() {
      this.pageIndex = Math.ceil(this.totalPage / this.pageSize);
      this.pageSelect();
    },

    customColorMethod() {
      return "#0BAA8E";
    },
    getDataQueryList() {
      this.dataListLoading = true;
      if (this.searchItems) {
        if (
          (this.searchItems.inputSearch &&
            this.searchItems.inputSearch.length > 0) ||
          (this.searchItems.examGrade &&
            this.searchItems.examGrade.length > 0) ||
          (this.searchItems.examYear && this.searchItems.examYear.length > 0) ||
          (this.searchItems.examType && this.searchItems.examType.length > 0) ||
          (this.searchItems.examSubject &&
            this.searchItems.examSubject.length > 0)
        ) {
          this.queryDataList();
        } else {
          this.getDataList();
        }
      } else {
        this.getDataList();
      }
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      fetchList(
        Object.assign({
          current: this.pageIndex,
          size: this.pageSize,
        })
      ).then((response) => {
        this.dataList = response.data.data.records;
        this.dataList.forEach((item) => {
          item.showSubjectList = true;
        });
        this.totalPage = response.data.data.total;
      });
      this.dataListLoading = false;
    },
    // 获取数据列表
    queryDataList() {
      this.dataListLoading = true;
      queryFetchList(
        Object.assign(
          {
            current: this.pageIndex,
            size: this.pageSize,
          },
          this.searchItems
        )
      ).then((response) => {
        this.dataList = response.data.data.records;
        this.dataList.forEach((item) => {
          item.showSubjectList = true;
        });
        this.totalPage = response.data.data.total;
      });
      this.dataListLoading = false;
    },

    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm("是否确认删除ID为" + id, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delObj(id);
        })
        .then((data) => {
          if (data && data.data && data.data.code === 0) {
            this.getDataList();
            this.$message.success("删除成功");
          } else {
            var msg = "";
            if (data && data.data && data.data.msg) {
              msg = data.data.msg;
            }
            if (!msg) {
              msg = "删除失败";
            }
            this.$message.error(msg);
          }
        });
    },
    examResultHandle(id) {
      this.goToReportDetailPage(id, "studentReport");
    },
    // 学生错题册/共性错题册
    goToReportDetailPage(row, tabActiveName, reportInfo) {
      // if (tabActiveName == "studentReport") {
      setCookie("reportType", reportInfo.type);
      let subjectNames = [];
      reportInfo.subjectList.forEach((item) => {
        subjectNames.push(
          item.subjectName + "_" + item.id + "_" + item.subjectId
        );
      });
      let tempReportInfo = reportInfo.name + ":" + subjectNames.toString();
      setCookie("reportInfo", tempReportInfo);
      // }

      this.$router.push({
        name: "analyStudentReport",
        query: {
          id: row.id,
          tab: tabActiveName,
        },
      });
    },
    //header
    statusFormatter(row) {
      if (row.status === 1) {
        return "进行中";
      } else if (row.status === 2) {
        return "已结束";
      } else if (row.status === 3) {
        return "已统计";
      }
    },
    findLabelByValue(options, value) {
      return findByValue(options, value);
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 考生管理
    examStudentHandle(examId, examName, examIds) {
      this.$router.push({
        name: "ExamNewStudent",
        query: {
          examId: examIds,
          examName: examName,
          // examIds: ,
        },
      });
    },
    // 模板设置
    // eslint-disable-next-line no-unused-vars
    examTemplateHandle(item) {
      this.$router.push({
        name: "exampaper",
        query: {
          id: item.examIds,
          subId: item.subjectList[0].subjectId,
          routerName: "analyReport",
        },
      });
      // this.$router.push({
      //   name: "templateedit",
      //   query: {
      //     examId: examId,
      //     examName: examName
      //   }
      // });
    },
    examNewResult(item) {
      this.$router.push({
        name: "ExamNewResult",
        query: {
          examId: item.examIds,
          examName: item.name,
          // status: status,
          routerName: "analyReport",
        },
      });
      // this.$router.push({
      //   name: "templateedit",
      //   query: {
      //     examId: examId,
      //     examName: examName
      //   }
      // });
    },
    // 试卷管理
    examPaperHandle(examId) {
      this.$router.push({
        name: "exampaper",
        query: {
          id: examId,
        },
      });
    },
    // 折叠条目..Expand item
    collapseTableItems() {
      this.dataList.forEach((item) => {
        if (item.showSubjectList) {
          item.showSubjectList = false;
          this.openAllItems = false;
        } else {
          item.showSubjectList = true;
          this.openAllItems = true;
        }
      });
    },
    //搜索下拉
    showSearchPopup() {
      if (this.showSearchItems) {
        this.showSearchItems = false;
      } else {
        this.showSearchItems = true;
      }
    },
    // 搜索按钮
    examSearch() {
      // // this.showSearchItems = false;
      //
      // Object.keys(this.searchItems).forEach(item => {
      //   // this.searchItems[item] = "";
      //   console.log(item);
      // });
      this.showSearchItems = false;
      this.pageIndex = 1;
      this.getDataQueryList();
    },
    calculateProgress(row) {
      let progress = 0;
      let generatedCount = row.generatedCount || 0;
      let studentCount = row.studentCount || 0;
      if (studentCount === 0 || generatedCount >= studentCount) {
        progress = 100;
      } else {
        progress = Math.round((generatedCount * 100) / studentCount);
      }
      return progress;
    },
  },
};
</script>

<style lang="scss">
//自带样式处理
.report-page {
  .el-table {
    color: #000000;
  }

  .el-table thead {
    font-size: 14px;
    line-height: 16px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.5);
  }

  .el-table--border,
  .el-table--group {
    border: 1px solid #e0e0e0;
  }

  .el-table th,
  .el-table tr {
    background-color: rgba(0, 0, 0, 0.0001);
  }

  tr:hover > td {
    background-color: rgba(0, 0, 0, 0.0001) !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #e0e0e0;
  }

  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #e0e0e0;
  }

  .el-table td,
  .el-table th {
    padding: 0;
    height: 32px;
  }

  .el-progress-bar__inner {
    border-radius: 2px;
  }

  .el-progress-bar__outer {
    border-radius: 2px;
    background-color: #dddddd;
  }

  .page-header-search {
    .main-search-input {
      .el-input__inner {
        background-color: unset;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
        border: unset;
      }
    }
  }

  .search-items {
    .search-item-input {
      .el-input .el-input__inner {
        width: 520px !important;
        height: 40px !important;
        border: none;
      }
    }

    .select-item-select {
      .el-select .el-input .el-input__inner {
        width: 176px !important;
        height: 40px !important;
        border-radius: 4px;
        background-color: #f5f5f5;
      }

      .el-select {
        margin-right: 16px;
        margin-top: 16px;
      }

      .el-select:nth-child(3n + 3) {
        margin-right: 0px;
      }

      .el-select .el-input.is-focus .el-input__inner,
      .el-select .el-input__inner:focus {
        border-color: #f5f5f5;
      }
    }
  }

  .el-dialog__wrapper {
    .el-button--primary {
      color: #fff;
      background-color: #2474ed;
      border-color: #2474ed;
    }
  }
}
</style>
<style scoped lang="scss">
.report-page {
  width: 1220px;
  margin: 0 auto;

  .report-page-header {
    height: 144px;
    padding: 0 10px;
    display: flex;
    align-items: center;

    .page-header-label {
      width: 120px;
      height: 48px;
      font-size: 24px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }

    .page-header-search {
      width: 368px;
      height: 48px;
      margin-left: 298px;

      .search-input {
        width: 368px;
        height: 48px;
        padding: 12px 16px;
        border-radius: 4px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .prefix {
          img {
            width: 24px;
            height: 24px;
            padding: 1px;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.5);
            margin-left: 16px;
          }
        }
      }

      .search-items {
        width: 592px;
        height: 284px;
        position: relative;
        left: -112px;
        top: -48px;
        z-index: 99;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
          0 0 2px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;

        .search-item-input {
          display: flex;

          .el-icon-caret-top {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background-color: #f5f5f5;
            text-align: center;
            line-height: 40px;
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .select-item-select {
          .el-input {
            width: 176px;
            height: 40px;
            margin-right: 16px;
            border-radius: 4px;
            background-color: #f5f5f5;
          }
        }

        .search-item-btn {
          width: 76px;
          height: 40px;
          border-radius: 4px;
          background-color: #2474ed;
          letter-spacing: 4px;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          line-height: 40px;
          float: right;
          cursor: pointer;
        }
      }
    }

    .page-header-btn {
      margin: 29px 0 0 278px;

      .new-items {
        width: 144px;
        height: 48px;
        padding: 12px 12px 12px 12px;
        border-radius: 4px;
        background-color: #2474ed;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
        text-align: center;

        .el-icon-plus {
          color: #ffffff;
        }

        .el-icon-plus:before {
          color: #ffffff;
        }

        span {
          font-size: 16px;
        }
      }

      .foldornot {
        color: black;
        font-family: PingFangSC;
        font-size: 14px;
        cursor: pointer;
        text-align: right;
        margin-top: 10px;
        float: right;
        position: relative;

        .openItems {
          position: relative;

          img {
            width: 12px;
          }
        }

        .foldItems {
          position: relative;
          left: 0;
          top: 0;

          img {
            width: 12px;
          }
        }
      }
    }
  }

  .report-page-content {
    .none-list {
      border: 1px solid #e0e0e0;
      margin-bottom: 64px;
      padding: 0 20px 60px;
      img {
        width: 400px;
        display: block;
        margin: 20px auto;
      }
      p {
        text-align: center;
        color: #999999;
      }
    }
    .each-report {
      width: 1220px;
      border-radius: 4px;
      margin-bottom: 32px;
      background-color: rgba(0, 0, 0, 0.0001);

      .report-header {
        padding: 24px;
        border: 1px solid #e0e0e0;
        border-bottom: none;
      }

      .report-header-only {
        padding: 24px;
        border: 1px solid #e0e0e0;
      }

      .report-info {
        display: flex;
        justify-content: space-between;

        .report-title {
          font-family: PingFangSC;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
        }

        .report-level {
          width: 376px;
          display: flex;
          font-size: 14px;
          line-height: 20px;

          .report-gradeortype {
            height: 20px;
            padding: 0 10px;
            background: #e6e6e4;
            border-radius: 4px;
            font-size: 12px;
            margin-left: 10px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      .report-btns {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;

        .btns-group {
          display: flex;
          color: #dddddd;

          .btns {
            padding: 0 8px;
            color: #2196f3;
            font-size: 12px;
            line-height: 16px;
            cursor: pointer;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .report-content {
        .report-table {
          .display-or-not {
            padding: 0 18px;

            .show-icon {
              width: 14px;
              height: 14px;
            }

            &:hover {
              text-align: center;
              justify-content: center;
              align-items: center;

              .show-icon,
              .show-progress {
                display: none;
              }

              .show-btn {
                display: block;
              }
            }

            .show-btn {
              width: 96px;
              border: 1px solid rgba(15, 15, 15, 0.1);
              color: rgba(0, 0, 0, 0.5);
              line-height: 24px;
              border-radius: 2px;
              display: none;
              cursor: pointer;

              img {
                width: 13px;
                margin-right: 10px;
                vertical-align: middle;
              }

              span {
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }

  .pagination {
    height: 80px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    line-height: 80px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 64px;

    .page-totle {
      margin-right: 64px;
      font-size: 14px;
      text-align: right;
      color: rgba(0, 0, 0, 0.85);
    }

    .page-select {
      width: 80px;
      height: 32px;
      margin: 0 20px 0 8px;
    }

    .page-jump {
      display: flex;
      list-style: none;

      & :not(:last-child) {
        margin-right: 32px;
      }

      & :last-child {
        margin-right: 8px;
      }

      li {
        width: 24px;
        height: 24px;

        img {
          vertical-align: middle;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
